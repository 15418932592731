import { I18n } from '@aws-amplify/core';
import { message, notification } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { customReportsService } from './services';
import { DeleteReportRequest, GetCustomReportsRequest } from './types/request';

export const useGetCustomReports = ({ organization_id, company_id, filter }: GetCustomReportsRequest) => {
	const enabled = !!organization_id && !!company_id;
	const customReports = useQuery(
		['custom_reports', [organization_id, company_id, filter]],
		() => customReportsService.fetchCustomReports({ organization_id, company_id, filter }),
		{
			enabled,
			retry: 0,
			select: (data) => data.filter((value) => value.name !== 'ewa_kinebot')
		}
	);
	return customReports;
};

export const useDeleteReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteReportRequest) => customReportsService.deleteReport(body), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(err?.response?.data?.message)
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['custom_reports']);
			message.success(I18n.get('Report deleted successfully'));
		}
	});
};
