import React, { useContext, useState } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import { Table } from 'antd';

import { MergeAEPContext } from '../context';
import { SelectedPEA } from '../types';

interface TableListProps {
	total: number;
	isLoading: boolean;
	peaList: SelectedPEA[];
	onPaginationChange: (currentPage: number, pageSize: number) => void;
}

export const TableList: React.FC<TableListProps> = ({ isLoading, total, peaList, onPaginationChange, ...props }) => {
	const {
		data: { pea_list },
		setData
	} = useContext(MergeAEPContext);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(pea_list ?? []);

	const rowSelection: TableRowSelection<SelectedPEA> = {
		selectedRowKeys,
		onChange: onSelectChange
	};

	function onSelectChange(selectedRowKeys: React.Key[]) {
		setSelectedRowKeys(selectedRowKeys);
		setData({
			pea_list: selectedRowKeys.length === 0 ? [] : setPEAList(selectedRowKeys)
		});
	}

	function setPEAList(selectedRowKeys: React.Key[]): React.Key[] {
		if (pea_list && !pea_list.some((pea) => selectedRowKeys.includes(pea))) {
			return [...pea_list, ...selectedRowKeys];
		}
		return selectedRowKeys;
	}

	const columns: ColumnsType<SelectedPEA> = [
		{
			title: I18n.get('File'),
			dataIndex: 'fileName',
			key: 'fileName',
			align: 'center',
			width: '25%'
		},
		{
			title: I18n.get('Analyst Name'),
			dataIndex: 'analystName',
			key: 'analystName',
			responsive: ['sm'],
			align: 'center'
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			responsive: ['sm'],
			align: 'center',
			key: 'score'
		},
		{
			title: I18n.get('Date of collection'),
			dataIndex: 'dateOfCollection',
			key: 'dateOfCollection',
			responsive: ['lg'],
			align: 'center'
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'createdAt',
			responsive: ['lg'],
			key: 'createdAt',
			align: 'center'
		}
	];

	return (
		<Table
			{...props}
			rowKey="id"
			columns={columns}
			loading={isLoading}
			rowSelection={rowSelection}
			pagination={{ showSizeChanger: false }}
			dataSource={peaList.filter((elem) => elem.score !== null)}
		/>
	);
};
