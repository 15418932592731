import { BaseService } from './base-service';
import { SignInParamsDTO } from '@/core/dto/auth/sign-in';
import {
	TFATypes,
	AuthUserDTO,
	Check2FADTO,
	Reset2FADTO,
	SendTokenDTO,
	Validate2FADTO,
	Generate2FADTO,
	ResendTokenDTO,
	ForgotPasswordDTO,
	ResetPasswordDTO,
	ResetPasswordRequest,
	Check2FABody,
	Reset2FABody,
	Validate2FABody,
	ResendTokenBody,
	SendTokenBody
} from '@/types/AuthUser';

export class AuthService extends BaseService {
	private static instance: AuthService;

	private constructor(public readonly basePath: string = '/auth') {
		super();
	}

	public static getInstance(): AuthService {
		if (!AuthService.instance) {
			AuthService.instance = new AuthService();
		}
		return AuthService.instance;
	}

	public async signIn(params: SignInParamsDTO): Promise<AuthUserDTO> {
		const url = this.basePath + '/session';
		const { data } = await this.getInstance().post<AuthUserDTO>(url, params);
		return data;
	}

	public async forgotPassword(email: string): Promise<ForgotPasswordDTO> {
		const url = this.basePath + '/forgot-password';
		const { data } = await this.getInstance().post<ForgotPasswordDTO>(url, { email });
		return data;
	}

	public async resetPassword(payload: ResetPasswordRequest): Promise<ResetPasswordDTO> {
		const url = this.basePath + '/reset-password';
		const { data } = await this.getInstance().post<ResetPasswordDTO>(url, payload);
		return data;
	}

	public async generate2FA(userId: string, type: TFATypes): Promise<Generate2FADTO> {
		const url = this.basePath + `/2fa/${userId}`;
		const { data } = await this.getInstance().post<Generate2FADTO>(url, { data: { type } });
		return data;
	}

	public async resendToken(userId: string, body: ResendTokenBody): Promise<ResendTokenDTO> {
		const url = this.basePath + `/2fa/resend/${userId}`;
		const { data } = await this.getInstance().post<ResendTokenDTO>(url, body);
		return data;
	}

	public async sendToken(userId: string, body: SendTokenBody): Promise<SendTokenDTO> {
		const url = this.basePath + `/2fa/send-token/${userId}`;
		const { data } = await this.getInstance().post<SendTokenDTO>(url, body);
		return data;
	}

	public async reset2FA(userId: string, body: Reset2FABody): Promise<Reset2FADTO> {
		const url = this.basePath + `/2fa/reset/${userId}`;
		const { data } = await this.getInstance().put<Reset2FADTO>(url, body);
		return data;
	}

	public async check(userId: string, body: Check2FABody) {
		const url = this.basePath + `/2fa/check/${userId}`;
		const { data } = await this.getInstance().post<Check2FADTO>(url, body);
		return data;
	}

	public async validate2FA(userId: string, body: Validate2FABody): Promise<Validate2FADTO> {
		const url = this.basePath + `/2fa/${userId}`;
		const { data } = await this.getInstance().put<Validate2FADTO>(url, body);
		return data;
	}
}
