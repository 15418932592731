import { useMutation } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { AuthService } from '@/infra/services/auth';
import { Reset2FARequest } from '@/types/AuthUser';

const Service = AuthService.getInstance();

function useReset2FA() {
	return useMutation(({ user_id, data }: Reset2FARequest) => Service.reset2FA(user_id, { data }), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			message.success(I18n.get('Two-factor authentication reset successfully'));
		}
	});
}

export { useReset2FA };
