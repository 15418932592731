import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import Api from '@/services/api';

export const useCreateActionPlan = () => {
	const queryClient = useQueryClient();
	return useMutation(({ body }) => Api.post('/action_plan', body), {
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['action_plans']);
			message.success(I18n.get('Action plan created successfully'));
		}
	});
};

export const useDeleteActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation(
		({ body }) => {
			const { actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}?organization_id=${organizationId}`;
			return Api.delete(url);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (data) => {
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Action plan deleted successfully'));
			}
		}
	);
};

export const useUpdateActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation(
		({ body }) => {
			const { action_plan, organizationId } = body;
			const url = `/action_plan/${action_plan.id}?organization_id=${organizationId}`;
			return Api.put(url, { action_plan });
		},
		{
			onError: (error, variables, context) => {
				console.log(error);
				queryClient.setQueryData(['action_plans', context?.previousValue]);
				message.error(I18n.get(error.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['action_plans']);
			}
		}
	);
};

export const useCreateTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { task, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task?organization_id=${organizationId}`;
			return Api.post(url, { task });
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task created successfully'));
			}
		}
	);
};

export const useUpdateTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { task, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task/${task.id}?organization_id=${organizationId}`;
			return Api.put(url, { task });
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task updated successfully'));
			}
		}
	);
};

export const useDeleteTask = () => {
	const queryClient = useQueryClient();
	return useMutation(
		({ body }) => {
			const { taskId, actionPlanId, organizationId } = body;
			const url = `/action_plan/${actionPlanId}/task/${taskId}?organization_id=${organizationId}`;
			return Api.delete(url);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['tasks']);
				queryClient.invalidateQueries(['action_plans']);
				message.success(I18n.get('Task deleted successfully'));
			}
		}
	);
};
