import { useMutation } from '@tanstack/react-query';
import { AuthService } from '@/infra/services/auth';
import { ResetPasswordRequest } from '@/types/AuthUser';

const Service = AuthService.getInstance();

function useResetPassword() {
	return useMutation((payload: ResetPasswordRequest) => Service.resetPassword(payload));
}

export { useResetPassword };
