import { useMutation } from '@tanstack/react-query';
import { AuthService } from '@/infra/services/auth';
import { SignInParamsDTO } from '@/core/dto/auth/sign-in';
import Api from '@/services/api';

const Service = AuthService.getInstance();

export function useSignIn() {
	return useMutation((params: SignInParamsDTO) => Service.signIn(params), {
		onSuccess: ({ token }) => {
			Api.defaults.headers.Authorization = `Bearer ${token}`;
		}
	});
}
