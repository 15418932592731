import { useMutation } from '@tanstack/react-query';
import { AuthService } from '@/infra/services/auth';

const Service = AuthService.getInstance();

function useForgotPassword() {
	return useMutation((email: string) => Service.forgotPassword(email));
}

export { useForgotPassword };
